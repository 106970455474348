import React from "react"
import PropTypes from "prop-types"
import "./Laporan.less"

class Laporan extends React.Component {
  render() {
    return (
      <a
        href={this.props.fileurl}
        download
        rel="noopener noreferrer"
        target="_blank"
        className="laporan-item"
      >
        <div className="laporan-item-inside">
          <div
            className="laporan-item-img"
            style={{ backgroundImage: `url(${this.props.image})` }}
          ></div>
        </div>
        <h3 className="laporan-item-title">{this.props.title}</h3>
      </a>
    )
  }
}

Laporan.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  fileurl: PropTypes.string,
}

export default Laporan
